import React, {useState, useEffect} from "react"
import {faGithub, faLinkedin} from "@fortawesome/free-brands-svg-icons"
import {faEnvelope} from "@fortawesome/free-solid-svg-icons"
import Fade from "react-reveal/Fade"
import FooterLink from "./FooterLink"
import DarkmodeToggle from "../DarkmodeToggle"

const expansionTriggerOffset = 200;
function shouldBeExpanded(){
    return window.innerHeight + window.scrollY >= document.body.scrollHeight - expansionTriggerOffset;
}
export default function Footer(){
    const [isExpanded, setIsExpanded] = useState(false)

    useEffect(() => {
        if(shouldBeExpanded()){
            setIsExpanded(true);
        }
        window.addEventListener("scroll", () => {
            if(shouldBeExpanded()){
                setIsExpanded(true);
            }else{
                setIsExpanded(false)
            }
        });
    }, [])

    return (
        <Fade bottom delay={500} duration={1500}>
            <footer className={`${isExpanded? "expanded": ""}`}>
                <div className="left">
                    <a>Projects</a>
                    <a>About</a>
                </div>
                <div className="center">
                    <FooterLink icon={faGithub} url="https://github.com/YassienW" isExpanded={isExpanded}/>
                    <FooterLink icon={faLinkedin} url="https://www.linkedin.com/in/yassien-w-777783172/" isExpanded={isExpanded}/>
                    <FooterLink icon={faEnvelope} url="mailto:yassien.w@gmail.com" isExpanded={isExpanded}/>
                </div>
                <div className="right">
                    <DarkmodeToggle/>
                </div>
            </footer>
        </Fade>
    )
}