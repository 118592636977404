import React, {useState, useEffect} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAdjust} from "@fortawesome/free-solid-svg-icons"

export default function DarkmodeToggle(){
    const [isDark, setIsDark] = useState(false)

    useEffect(() => {
        if(isDark){
            document.body.className = "dark-mode";
        }else{
            document.body.className = "";
        }
    }, [isDark])

    return (
        <button id="contrast-toggle" onClick={() => setIsDark(!isDark)}>
            <FontAwesomeIcon icon={faAdjust}/>
        </button>
    )
}