import React from "react"
import Footer from "./footer/Footer"

export default function Layout({children}){
    return (
        <>
            <div id="container">
                {children}
            </div>
            <Footer/>
        </>
    )
}