import React, {useEffect} from "react"
import Vara from "vara"

export default function HandwrittenHeader(){
    useEffect(() => {
        new Vara("#header", "PacificoSLO.json", [{text: "Hello", y: 2}],
            {fontSize: 70, textAlign: "center", strokeWidth: 1, duration: 1800});
    }, [])

    return (
        <div id="header"/>
    )
}