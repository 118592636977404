import React from "react"
import Layout from "../components/Layout"
import Projects from "../components/projects/Projects"
import HandwrittenHeader from "../components/HandwrittenHeader"

export default () => (
    <Layout>
        <HandwrittenHeader/>
        <Projects/>
    </Layout>
)