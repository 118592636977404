import React from "react"

export default function Project({name, description, image, url}) {
    return (
        <a className="project" href={url}>
            <h5>{name}</h5>
            <img src={image} alt={image}/>
            <p>{description}</p>
        </a>
    )
}