import React from "react"
import Project from "./Project"
import Fade from 'react-reveal/Fade';
import projects from "../../projects"

export default function Projects() {
    return (
        <div id="projects">
            {projects.map((project, index) => {
                const {name, description, image, url} = project;
                return(
                    <Fade key={index} delay={200 * index} duration={2000}>
                        <Project name={name} description={description} image={image} url={url}/>
                    </Fade>
                )
            })}
        </div>
    )
}